<template>
  <ion-page><cyan-page-wrapper title="Elegir provincia" :burst="true" backRoute="/home" nav1="CONTROL ELECTORAL">
    <mapa-sv nextRoute="/agentePickMpio/" :key="'mapaAgentePickDepto'"/>
    <territory-picker nextRoute="/agentePickMpio/" />
  </cyan-page-wrapper></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, { defineComponent, IonPage } from '@/components/CyanPageWrapper.vue'
import TerritoryPicker from '@/components/TerritoryPicker.vue';
import MapaSv from '@/components/MapaSv.vue';

export default defineComponent({
  name: "AgentePickDepto",
  components: {
    TerritoryPicker,
    CyanPageWrapper,
    IonPage,
    MapaSv
  },
  computed: {},
  methods: {}
});
</script>
